import React from "react";

const Titles = () =>(
    <div className="row">
        <div className="col-12 text-center">
            <img className="logo" src={ require('../img/weather-finder.png') } />
        </div>
        <div className="col-12 text-center">
            <p className="text-center mb-5">Find out temperature, conditions and more...</p>
        </div>
    </div>
);

export default Titles;