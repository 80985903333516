import React from "react";
import Titles from "./components/Titles";
import Form from "./components/Form";
import Weather from "./components/Weather";

const WEATHER_API_TOKEN = "6239562e696c7018e75732c17eea87c2";

class App extends React.Component {
    state = {
        temperature: undefined,
        city: undefined,
        country: undefined,
        humidity: undefined,
        description: undefined,
        error: undefined
    };
    getWeather = async e => {
        e.preventDefault();
        const city = e.target.elements.city.value;
        const country = e.target.elements.country.value;
        const api_call = await fetch(`https://api.openweathermap.org/data/2.5/weather?q=${city},${country}&units=metric&appid=${WEATHER_API_TOKEN}`);
        const data = await api_call.json();
        console.log(api_call);
        if (api_call.status === 200) {
            console.log(data);
            this.setState({
                temperature: data.main.temp,
                city: data.name,
                country: data.sys.country,
                humidity: data.main.humidity,
                description: data.weather[0].description,
                error: ""
            });
        } else {
            this.setState({
                temperature: undefined,
                city: undefined,
                country: undefined,
                humidity: undefined,
                description: undefined,
                error: "Please enter the values asked above"
            });
        }
    };
    render() {
        return (
            <div>
                <div className="wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-7 form-container borders">
                                <Titles/>
                                <Weather
                                    temperature={this.state.temperature}
                                    city={this.state.city}
                                    country={this.state.country}
                                    humidity={this.state.humidity}
                                    description={this.state.description}
                                    error={this.state.error}/>
                                <div className="">
                                    <Form getWeather={this.getWeather}/>
                                </div>
                            </div>
                            <div className="col-5 bg-container borders"/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default App;
